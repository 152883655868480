import type { NuxtRuntimeConfig } from "@nuxt/types/config/runtime"
import * as moment from "moment-timezone"
import "moment-timezone"
declare module "vue/types/vue" {
  interface Vue {
    $moment: (inp?: moment.MomentInput, strict?: boolean) => moment.Moment
  }
}

export default (app: NuxtRuntimeConfig) => {
  app.provide("moment", moment)
}
