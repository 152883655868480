import { generateUuid } from "@evercam/shared/utils"

export default (app) => {
  const redirectToSigninPage = (idp) => {
    const state = generateUuid()
    const currentPath = app.nuxt2Context.route.fullPath
    const { evercamAuthUrl, evercamAuthClientId } =
      app.nuxt2Context.$config.public
    const provider = evercamAuthClientId.includes("dash")
      ? "evercam"
      : "evercamAdmin"

    window.localStorage?.setItem("sourceUrlRequestedSso", currentPath)
    window.localStorage?.setItem("ssoState", state)
    window.localStorage?.setItem("ssoProvider", provider)

    const scope = "openid email profile"
    const idp_hint = idp ? `&kc_idp_hint=${idp}` : ""
    const queryParams = `response_type=code&client_id=${evercamAuthClientId}&scope=${scope}&redirect_uri=${window.location.origin}/auth/${provider}&state=${state}${idp_hint}`

    app.nuxt2Context.redirect(`${evercamAuthUrl}?${queryParams}`)
  }

  app.provide("keycloak", {
    redirectToSigninPage,
  })
}
