import { defineStore } from "pinia"
import { NotificationParams } from "@evercam/shared/plugins/notifications"

export const useNotificationStore = defineStore({
  id: "notification",
  state: (): NotificationParams => ({
    text: "",
    color: "",
    snackbar: false,
    error: null,
    timeout: 3000,
  }),
  actions: {
    setNotification({
      text,
      color,
      snackbar,
      error,
      timeout,
    }: NotificationParams) {
      this.text = text
      this.color = color
      this.snackbar = snackbar
      this.error = error
      this.timeout = timeout
    },
  },
})
