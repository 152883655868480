import {
  ConfirmDialogState,
  useConfirmDialogStore,
} from "@evercam/shared/stores/confirmDialog"

export default (app) => {
  const open = async (params: ConfirmDialogState) => {
    // Assuming `useConfirmDialogStore` is reactive
    const confirmDialogStore = useConfirmDialogStore()

    return await confirmDialogStore.open(params)
  }

  app.provide("confirmDialog", { open })
}
