export interface WeatherIcon {
  slug: string
  descriptionKey: string
}

export const weatherIcons: Record<number, WeatherIcon> = {
  113: { slug: "clear-day", descriptionKey: "weather.clear_sunny" },
  116: { slug: "cloudy-2-day", descriptionKey: "weather.partly_cloudy" },
  119: { slug: "cloudy", descriptionKey: "weather.cloudy" },
  122: { slug: "cloudy-1-day", descriptionKey: "weather.overcast" },
  143: { slug: "fog", descriptionKey: "weather.mist" },
  176: { slug: "rainy-1", descriptionKey: "weather.patchy_rain_nearby" },
  179: { slug: "snowy-1", descriptionKey: "weather.patchy_snow_nearby" },
  182: {
    slug: "rain-and-sleet-mix",
    descriptionKey: "weather.patchy_sleet_nearby",
  },
  185: {
    slug: "rain-and-sleet-mix",
    descriptionKey: "weather.patchy_freezing_drizzle_nearby",
  },
  200: {
    slug: "isolated-thunderstorms",
    descriptionKey: "weather.thundery_outbreaks_nearby",
  },
  227: { slug: "snowy-1", descriptionKey: "weather.blowing_snow" },
  230: { slug: "snowy-3", descriptionKey: "weather.blizzard" },
  248: { slug: "fog", descriptionKey: "weather.fog" },
  260: { slug: "fog", descriptionKey: "weather.freezing_fog" },
  263: { slug: "rainy-1", descriptionKey: "weather.patchy_light_drizzle" },
  266: { slug: "rainy-1", descriptionKey: "weather.light_drizzle" },
  281: { slug: "rainy-1", descriptionKey: "weather.freezing_drizzle" },
  284: {
    slug: "rain-and-sleet-mix",
    descriptionKey: "weather.heavy_freezing_drizzle",
  },
  293: { slug: "rainy-1", descriptionKey: "weather.patchy_light_rain" },
  296: { slug: "rainy-1", descriptionKey: "weather.light_rain" },
  299: { slug: "rainy-2", descriptionKey: "weather.moderate_rain_at_times" },
  302: { slug: "rainy-2", descriptionKey: "weather.moderate_rain" },
  305: { slug: "rainy-3", descriptionKey: "weather.heavy_rain_at_times" },
  308: { slug: "rainy-3", descriptionKey: "weather.heavy_rain" },
  311: {
    slug: "rain-and-sleet-mix",
    descriptionKey: "weather.light_freezing_rain",
  },
  314: {
    slug: "rain-and-sleet-mix",
    descriptionKey: "weather.moderate_or_heavy_freezing_rain",
  },
  317: { slug: "rain-and-sleet-mix", descriptionKey: "weather.light_sleet" },
  320: {
    slug: "rain-and-sleet-mix",
    descriptionKey: "weather.moderate_or_heavy_sleet",
  },
  323: { slug: "snowy-1", descriptionKey: "weather.patchy_light_snow" },
  326: { slug: "snowy-1", descriptionKey: "weather.light_snow" },
  329: { slug: "snowy-2", descriptionKey: "weather.patchy_moderate_snow" },
  332: { slug: "snowy-2", descriptionKey: "weather.moderate_snow" },
  335: { slug: "snowy-3", descriptionKey: "weather.patchy_heavy_snow" },
  338: { slug: "snowy-3", descriptionKey: "weather.heavy_snow" },
  350: { slug: "snow-and-sleet-mix", descriptionKey: "weather.ice_pellets" },
  353: { slug: "rainy-1", descriptionKey: "weather.light_rain_shower" },
  356: {
    slug: "rainy-2",
    descriptionKey: "weather.moderate_or_heavy_rain_shower",
  },
  359: { slug: "rainy-3", descriptionKey: "weather.torrential_rain_shower" },
  362: {
    slug: "rain-and-sleet-mix",
    descriptionKey: "weather.light_sleet_showers",
  },
  365: {
    slug: "rain-and-sleet-mix",
    descriptionKey: "weather.moderate_or_heavy_sleet_showers",
  },
  368: { slug: "snowy-1", descriptionKey: "weather.light_snow_showers" },
  371: {
    slug: "snowy-3",
    descriptionKey: "weather.moderate_or_heavy_snow_showers",
  },
  374: {
    slug: "rain-and-sleet-mix",
    descriptionKey: "weather.light_showers_of_ice_pellets",
  },
  377: {
    slug: "rain-and-sleet-mix",
    descriptionKey: "weather.moderate_or_heavy_showers_of_ice_pellets",
  },
  386: {
    slug: "isolated-thunderstorms",
    descriptionKey: "weather.patchy_light_rain_with_thunder",
  },
  389: {
    slug: "thunderstorms",
    descriptionKey: "weather.moderate_or_heavy_rain_with_thunder",
  },
  392: {
    slug: "thunderstorms",
    descriptionKey: "weather.patchy_light_snow_with_thunder",
  },
  395: {
    slug: "thunderstorms",
    descriptionKey: "weather.moderate_or_heavy_snow_with_thunder",
  },
}
