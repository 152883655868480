import { SECOND } from "@evercam/shared/constants/schedules"
import { useNotificationStore } from "@evercam/shared/stores/notification"

export interface NotificationParams {
  text: string
  color?: string
  snackbar?: boolean
  error?: Error
  timeout?: number
}

export default (app) => {
  const push = (params: NotificationParams) => {
    useNotificationStore().setNotification(params)
  }
  const formatValidationError = (errorDetails, customMessage) => {
    const formattedCode = errorDetails[0]?.code?.toLowerCase()
    const formattedTarget =
      errorDetails[0]?.target?.charAt(0)?.toUpperCase() +
      errorDetails[0]?.target?.slice(1)
    if (!formattedCode || !formattedTarget || formattedCode === "length") {
      return `${customMessage?.toString()} : ${errorDetails[0]?.message}`
    }
    const translatedMessage = app.nuxt2Context.i18n
      .t(`generic_errors.${formattedCode}`, {
        target: formattedTarget,
      })
      .toString()

    return `${customMessage?.toString()} : ${translatedMessage}`
  }

  const formatGenericError = (backendErrorCode, customMessage) => {
    const formattedCode = backendErrorCode.toLowerCase()
    const translatedMessage = app.nuxt2Context.i18n
      .t(`generic_errors.${formattedCode}`)
      .toString()

    return `${customMessage?.toString()} : ${translatedMessage}`
  }

  const handleErrorMessage = (customMessage, error, showDetails) => {
    const defaultMessage = app.nuxt2Context.i18n
      .t("content.something_wrong_text")
      .toString()
    if (!error || !showDetails) {
      return customMessage || defaultMessage
    }

    const backendError = error?.response?.data
    const errorDetails = backendError?.details

    if (
      !errorDetails?.length &&
      !backendError?.code &&
      !backendError?.inner_error
    ) {
      if (
        error?.name === "AxiosError" &&
        app.nuxt2Context.$config.public.appName === "admin"
      ) {
        return `${customMessage} (AxiosError, code: ${error.code}, message: ${error.message})  `
      } else if (error?.code === "ERR_BAD_REQUEST" && backendError?.message) {
        return `${customMessage} : ${backendError.message}`
      } else {
        return (
          backendError?.message ||
          backendError?.description ||
          error?.message ||
          customMessage ||
          defaultMessage
        )
      }
    }

    if (
      errorDetails?.length &&
      ((errorDetails[0]?.code && errorDetails[0]?.target) ||
        errorDetails[0]?.message)
    ) {
      return formatValidationError(errorDetails, customMessage)
    }

    if (backendError?.inner_error) {
      const innerError = { response: { data: backendError.inner_error } }

      return handleErrorMessage(customMessage, innerError, showDetails)
    }

    if (backendError?.code) {
      return formatGenericError(backendError.code, customMessage)
    }

    return customMessage || defaultMessage
  }

  const error = ({
    text = "",
    error,
    notifyOnCliq = false,
    notifyParams = {},
    showDetails = true,
    timeout = 6 * SECOND,
  }: {
    text?: string
    error?: Error
    notifyOnCliq?: boolean
    notifyParams?: object
    showDetails: boolean
    timeout?: number
  }) => {
    if (error?.message === "Request aborted") {
      console.warn("Request aborted")

      return
    }
    if (app.$axios.isCancel(error)) {
      console.warn("Operation canceled due to new request")

      return
    }

    if (error instanceof Error || typeof error === "string") {
      notifyOnCliq
        ? app.nuxt2Context.$errorTracker.saveAndNotify(notifyParams)
        : app.nuxt2Context.$errorTracker?.save(error)
    }

    push({
      text: handleErrorMessage(text, error, showDetails),
      color: "error",
      snackbar: true,
      error,
      timeout,
    })

    // Log the error to the console
    console.warn(error)
  }

  const success = (text: string) => {
    push({
      text,
      color: "success",
      snackbar: true,
    })
  }

  const info = (text: string) => {
    push({
      text,
      color: "primary",
      snackbar: true,
    })
  }

  const warn = (text: string, params = {}) => {
    push({
      text,
      color: "error",
      snackbar: true,
      ...params,
    })
  }

  app.provide("notifications", {
    push,
    error,
    success,
    info,
    warn,
  })
}
