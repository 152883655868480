export default (accountStore) => async (app) => {
  const getToken = () => {
    return accountStore().token
  }

  const hasToken = () => !!getToken()

  const isTokenExpired = () => {
    let tokenExpiration
    try {
      const jwt = JSON.parse(atob(getToken().split(".")[1]))
      tokenExpiration = jwt.exp
    } catch (e) {
      app.nuxt2Context.$errorTracker?.save(e)

      return true
    }

    return tokenExpiration < new Date().getTime() / 1000
  }

  const logout = async (props) => {
    await accountStore().logout(props)
  }

  const getUserInfo = () => {
    return {
      name: `${accountStore()?.firstname} ${accountStore()?.lastname}`,
      email: accountStore()?.email,
    }
  }

  const getPersistedToken = () => {
    let persistedToken
    try {
      const isDemoIframe = parent !== window
      let localStorageState
      if (isDemoIframe) {
        localStorageState = accountStore().isWidget
          ? localStorage?.getItem("dashboardWidgetStates")
          : localStorage?.getItem("dashboardDemoStates")
      } else {
        localStorageState = localStorage?.getItem("dashboardAccountStates")
      }
      persistedToken = JSON.parse(localStorageState)?.token
    } catch (e) {
      app.nuxt2Context.$errorTracker.save(e)
    }

    return persistedToken
  }

  const isDoubleLogin = () => {
    const currentToken = accountStore().token
    const persistedToken = getPersistedToken()

    if (!persistedToken) {
      return true
    }

    return currentToken !== persistedToken
  }

  const isValidSsoAuthRedirectedUrl = () => {
    const queryObject = new URLSearchParams(document.location.search)

    return (
      queryObject.get("code") &&
      queryObject.get("state") === window.localStorage?.getItem("ssoState")
    )
  }

  app.provide("auth", {
    isTokenExpired,
    isDoubleLogin,
    getPersistedToken,
    isValidSsoAuthRedirectedUrl,
    getToken,
    hasToken,
    logout,
    getUserInfo,
  })
}
