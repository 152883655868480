import { defineStore } from "pinia"

export type DialogOptions = {
  width: number
  zIndex: number
  confirmText: string
  cancelText: string
}

export type ConfirmDialogState = {
  dialog?: boolean
  message: string
  title: string
  options?: DialogOptions
  resolve?: (value: any) => void
}

export const useConfirmDialogStore = defineStore({
  id: "confirmDialog",
  state: (): ConfirmDialogState => ({
    dialog: false,
    resolve: null,
    message: null,
    title: null,
    options: {
      width: 350,
      zIndex: 200,
      confirmText: "Yes",
      cancelText: "Cancel",
    },
  }),
  actions: {
    open({ title, message, options = null }: ConfirmDialogState) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = { ...this.options, ...options }

      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    agree() {
      const resolve = this.resolve
      resolve(true)
      this.dialog = false
    },
    cancel() {
      const resolve = this.resolve
      resolve(false)
      this.dialog = false
    },
  },
})
